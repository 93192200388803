import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function Dashboard() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/'); // Redireciona para a página de login se o token não existir
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove o token
    navigate('/'); // Redireciona para a página de login
    window.location.reload(); // Força uma atualização da página
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Dashboard - CDN for Baksa</h1>
      <h1 style={styles.subtitle}>AngryCode - AngryCode.com.br</h1>
      <nav style={styles.nav}>
        <ul style={styles.ul}>
          <li style={styles.li}>
            <Link to="/domains" style={styles.link}>Gerenciar Domínios</Link>
          </li>
          <li style={styles.li}>
            <Link to="/upload" style={styles.link}>Upload de Vídeos</Link>
          </li>
          <li style={styles.li}>
            <Link to="/gallery" style={styles.link}>Galeria de Vídeos</Link>
          </li>
        </ul>
      </nav>
      <button onClick={handleLogout} style={styles.button}>Logout</button>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    fontFamily: 'Roboto, sans-serif',
    background: 'linear-gradient(135deg, #1E90FF, #222)', // Gradiente de azul para branco
    color: '#333',
  },
  title: {
    // marginTop: '-50px',
    fontSize: '2.5rem',
    color: '#ffffff',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
  },
  subtitle: {
    marginBottom: '40px',
    color: '#ffffff',
    fontSize: '10px',
  },
  nav: {
    marginBottom: '30px',
  },
  ul: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    gap: '20px',
  },
  li: {
    margin: 0,
  },
  link: {
    textDecoration: 'none',
    fontSize: '1.2rem',
    color: '#007BFF',
    backgroundColor: '#ffffff',
    padding: '10px 20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s, box-shadow 0.3s',
  },
  linkHover: {
    backgroundColor: '#0056b3',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
  },
  button: {
    padding: '10px 20px',
    fontSize: '1rem',
    color: '#fff',
    backgroundColor: '#ff4d4d',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  buttonHover: {
    backgroundColor: '#cc0000',
  },
};

export default Dashboard;
