import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';

function VideoGallery() {
  const [videos, setVideos] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Verifica se o token existe; caso contrário, redireciona para a página inicial
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/');
    } else {
      const fetchVideos = async () => {
        try {
          const response = await axios.get('https://104.234.224.98:3443/videos2', {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data',
              'Cache-Control': 'no-cache', // Desativa o cache
              // 'Pragma': 'no-cache',
              // 'Expires': '0',
            },
          });
          setVideos(response.data);
        } catch (error) {
          console.error('Erro ao buscar vídeos:', error);
        }
      };

      fetchVideos();
    }
  }, [navigate]);

  const handleRetornar = () => {
    navigate('/dashboard'); // Redireciona para a página de login
    window.location.reload(); // Força uma atualização da página
  };

  return (
    <div style={styles.galleryContainer}>
      <h1 style={styles.title}>Galeria de Vídeos</h1>
      <div style={styles.videoGrid}>
        {videos.map((video, index) => (
          <div key={index} style={styles.videoContainer}>
            <h2 style={styles.videoTitle}>{video}</h2>
            <div style={styles.videoWrapper}>
              <ReactPlayer
                url={`https://104.234.224.98:3443/videos/${video}`}
                controls
                width="100%"
                height="100%"
                style={styles.videoPlayer}
              />
            </div>
            <a
              href={`https://104.234.224.98:3443/videos/${video}`}
              target="_blank"
              rel="noopener noreferrer"
              style={styles.videoLink}
            >
              Assistir Externo
            </a>
          </div>
        ))}
      </div>
      <button onClick={handleRetornar} style={styles.returnButton}>
        Retornar
      </button>
    </div>
  );
}

const styles = {
  galleryContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    fontFamily: 'Roboto, sans-serif',
    background: 'linear-gradient(135deg, #1E90FF, #222)',
    color: '#ffffff',
  },
  title: {
    fontSize: '2.5rem',
    color: '#ffffff',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
    marginBottom: '40px',
  },
  videoGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  videoContainer: {
    margin: '15px',
    border: '2px solid #ddd',
    padding: '15px',
    borderRadius: '12px',
    width: '320px',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  videoWrapper: {
    position: 'relative',
    width: '100%',
    paddingTop: '56.25%', // 16:9 Aspect Ratio
    overflow: 'hidden',
    backgroundColor: '#000',
    borderRadius: '8px',
    marginBottom: '10px',
  },
  videoPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  videoTitle: {
    fontSize: '18px',
    color: '#333',
    marginBottom: '15px',
    fontWeight: 'bold',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  videoLink: {
    display: 'block',
    marginTop: '10px',
    fontSize: '1rem',
    color: '#007bff',
    textDecoration: 'none',
    borderRadius: '5px',
    transition: 'color 0.3s',
  },
  returnButton: {
    marginTop: '20px',
    padding: '10px 20px',
    fontSize: '1rem',
    color: '#fff',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
};

export default VideoGallery;
