import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function VideoUpload() {
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Verifica se o token existe; caso contrário, redireciona para a página inicial
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/');
    }
  }, [navigate]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('video', file);
  
    try {
      await axios.post('https://104.234.224.98:3443/upload2', formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
          'Cache-Control': 'no-cache', // Desativa o cache
          'Pragma': 'no-cache',
          'Expires': '0',
        },
      });
      alert('Upload successful');
    } catch (error) {
      alert('Upload failed');
      console.log(error);
    }
  };
  

  const handleRetornar = () => {
    navigate('/dashboard'); // Redireciona para a página de login
    window.location.reload(); // Força uma atualização da página
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Upload de Vídeos</h1>
      <input type="file" onChange={handleFileChange} style={styles.fileInput} />
      <button onClick={handleUpload} style={styles.uploadButton}>
        Upload
      </button>
      <button onClick={handleRetornar} style={styles.returnButton}>
        Retornar
      </button>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    fontFamily: 'Roboto, sans-serif',
    background: 'linear-gradient(135deg, #1E90FF, #222)', // Gradiente de azul-marinho para branco
    padding: '20px',
  },
  title: {
    marginBottom: '20px',
    fontSize: '2rem',
    color: '#fff',
  },
  fileInput: {
    marginBottom: '20px',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '1rem',
    cursor: 'pointer',
    backgroundColor: '#fff',
  },
  uploadButton: {
    padding: '10px 20px',
    fontSize: '1rem',
    color: '#fff',
    backgroundColor: '#28a745',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  uploadButtonHover: {
    backgroundColor: '#218838',
  },
  returnButton: {
    marginTop: '20px',
    padding: '10px 20px',
    fontSize: '1rem',
    color: '#fff',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  returnButtonHover: {
    backgroundColor: '#0069d9',
  },
};

export default VideoUpload;
