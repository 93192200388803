import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login'; // Certifique-se de que isso corresponda à exportação
import Dashboard from './components/Dashboard';
import Domains from './components/Domains';
import VideoUpload from './components/VideoUpload';
import VideoGallery from './components/VideoGallery';

function App() {
  const isAuthenticated = () => {
    return !!localStorage.getItem('token');
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={isAuthenticated() ? <Navigate to="/dashboard" /> : <Login />} />
        <Route path="/dashboard" element={isAuthenticated() ? <Dashboard /> : <Navigate to="/" />} />
        <Route path="/domains" element={isAuthenticated() ? <Domains /> : <Navigate to="/" />} />
        <Route path="/upload" element={isAuthenticated() ? <VideoUpload /> : <Navigate to="/" />} />
        <Route path="/gallery" element={isAuthenticated() ? <VideoGallery /> : <Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
